<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <q-form ref="giveEditForm">
        <c-card title="기본정보" class="cardClassDetailForm">
          <template slot="card-detail">
            <div class="col-3">
              <c-plant
                :disabled="true"
                :editable="editable"
                type="edit"
                name="plantCd"
                v-model="param.giveEquipment.plantCd"
              />
            </div>
            <div class="col-3">
              <c-field
                label='신청자'
                :disabled="true"
                :editable="editable"
                :data="param.giveEquipment"
                type="dept_user"
                name="regUserId"
                v-model="param.giveEquipment.regUserId"
              />
            </div>
            <div class="col-3">
              <c-text
                :readonly="true"
                :disabled="true"
                :close="true"
                :editable="editable"
                label="밀폐공간"
                name="spaceNameName"
                v-model="param.giveEquipment.spaceNameName">
              </c-text>
            </div>
          </template>
        </c-card>
      </q-form>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-table
        ref="tableGive"
        title="장비 대여/반납 목록"
        tableId="table"
        :editable="editable && !disabled"
        :columns="gridGive.columns"
        :data="param.giveEquipment.closedSpaceEquipmentReturnItemModels"
        rowKey="heaClosedSpaceEquipmentReturnItemId"
        checkDisableColumn="rowDisabled"
        :merge="gridGive.merge"
        :isFullScreen="false"
        :columnSetting="false"
        :filtering="false"
        :usePaging="false"
        :isExcelDown="false"
        gridHeight="670px"
        selection="multiple"
      >
        <!-- 버튼 영역 -->
        <template slot="table-button">
          <div>
            <q-btn-group outline>
              <c-btn 
                v-show="editable && !disabled" 
                label="신청목록 선택"
                icon="save_alt" 
                @btnClicked="addRequestEquips" />
              <c-btn 
                v-show="editable && !disabled 
                && this.param.giveEquipment.closedSpaceEquipmentReturnItemModels.length > 0
                && checkApply === true" 
                label="대여" 
                icon="check" 
                @btnClicked="rentEquip" />
              <c-btn 
                v-show="editable && !disabled
                && this.param.giveEquipment.closedSpaceEquipmentReturnItemModels.length > 0"
                label="반납" 
                icon="check" 
                @btnClicked="returnEquip" />
              <c-btn 
                v-show="editable && !disabled" 
                label="LBLADD" 
                icon="add" 
                @btnClicked="addEquips" />
              <c-btn 
                v-show="editable && !disabled" 
                label="LBLSAVE" 
                :url="saveUrl"
                :isSubmit="isSaveGive"
                :param="param.giveEquipment"
                mappingType="PUT"
                icon="save" 
                @beforeAction="saveGive"
                @btnCallback="saveCallback" />
              <c-btn 
                v-show="editable && !disabled" 
                label="LBLREMOVE" 
                icon="remove" 
                @btnClicked="removeGive" />
            </q-btn-group>
          </div>
        </template>
      </c-table>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import { uid } from 'quasar'
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'closedSpaceEquipGive',
  props: {
    param: {
      type: Object,
      default: () => ({
        heaClosedSpaceEquipmentGiveId: '',
        giveEquipment: {
          heaClosedSpaceEquipmentGiveId: '',
          plantCd: '',
          requestDate: '',
          regUserId: '',
          spaceNameCd: null,
          spaceNameName: '',
          giveStatusCd: '',
          requestStepCd: '',
          closedSpaceEquipmentGiveItemModels: [],
          deleteClosedSpaceEquipmentGiveItemModels: [],
          closedSpaceEquipmentReturnItemModels: [],
          deleteClosedSpaceEquipmentReturnItemModels: [],
        },
      }),
    },
  },
  data() {
    return {
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      gridGive: {
        columns: [
          {
            name: 'equipmentTypeName',
            field: 'equipmentTypeName',
            label: '장비종류',
            style: 'width:200px',
            align: 'center',
            sortable: false,
          },
          {
            name: 'equipmentName',
            field: 'equipmentName',
            label: '장비명',
            style: 'width:60px',
            align: 'center',
            sortable: false,
          },
          {
            name: 'equipmentNumber',
            field: 'equipmentNumber',
            label: '신청수량',
            style: 'width:60px',
            align: 'right',
            sortable: false,
          },
          {
            name: 'equipmentRentNumber',
            field: 'equipmentRentNumber',
            label: '대여수량',
            style: 'width:60px',
            align: 'right',
            type: 'number',
            sortable: false,
            disableTarget: 'rentStatusCd',
            disableCon: 'HCSEGC0002'
          },
          {
            name: 'rentStatusName',
            field: 'rentStatusName',
            label: 'LBLSTEPPROGRESS',
            style: 'width:100px',
            align: 'center',
          },
          {
            required: true,
            name: 'rentPeriod',
            field: 'rentPeriod',
            label: '대여기간',
            style: 'width:120px',
            align: 'center',
            range: true,
            type: 'date',
            sortable: false,
            disableTarget: 'rentStatusCd',
            disableCon: 'HCSEGC0002'
          },
          {
            required: true,
            name: 'rentDate',
            field: 'rentDate',
            label: '대여일',
            style: 'width:120px',
            align: 'center',
            type: 'date',
            sortable: false,
            disableTarget: 'rentStatusCd',
            disableCon: 'HCSEGC0002'
          },
          {
            required: true,
            name: 'returnDate',
            field: 'returnDate',
            label: '반납일',
            style: 'width:120px',
            align: 'right',
            type: 'date',
            sortable: false,
          },
          {
            name: 'remark',
            field: 'remark',
            label: 'LBLREMARK',
            align: 'left',
            type: 'text',
            style: 'width:250px',
            sortable: false,
          },
        ],
        data: [],
      },
      editable: true,
      saveType: 'POST',
      isSaveRequest: false,
      isSaveGive: false,
      isComplete: false,
      detailUrl: '',
      saveUrl: '',
      deleteItemUrl: '',
      rentUrl: '',
      returnUrl: '',
      rentStatusUrl: '',
    };
  },
  computed: {
    disabled() {
      return this.param.giveEquipment.giveStatusCd === 'CSE0000005'
    },
    checkApply() {
      let items = this.$_.filter(this.param.giveEquipment.closedSpaceEquipmentReturnItemModels, {rentStatusCd: 'HCSEGC0002'})
      let checkApply = false
      if(items.length > 0) {
        checkApply = true;
      }
      return checkApply;
    },
  },
  watch: {
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {},
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.saveUrl = transactionConfig.hea.cse.gives.returnUpdate.url;
      this.rentUrl = transactionConfig.hea.cse.gives.rent.url;
      this.returnUrl = transactionConfig.hea.cse.gives.return.url;
      this.deleteItemUrl = transactionConfig.hea.cse.gives.deleteItem.url;
      this.rentStatusUrl = transactionConfig.hea.cse.gives.status.rent.url;
      // code setting
      // list setting
      
    },
    /* eslint-disable no-unused-vars */
    completeRequest() { 
    },
    saveGive() {
      this.$refs['giveEditForm'].validate().then(_result => {
        if (_result) { 
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.param.giveEquipment.regUserId = this.$store.getters.user.userId
              this.param.giveEquipment.chgUserId = this.$store.getters.user.userId   

              this.$_.forEach(this.param.giveEquipment.closedSpaceEquipmentReturnItemModels, _item => {
                if (_item.rentPeriod && _item.rentPeriod.length > 0) {
                  _item.rentStartDate = _item.rentPeriod[0]
                  _item.rentEndDate = _item.rentPeriod[1]
                }
              })

              this.isSaveGive = !this.isSaveGive;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.param.heaClosedSpaceEquipmentGiveId = result.data
      this.$emit('emitStep', {
        name: 'getDetail',
      })
    },
    rentEquip() {
      let selectData = this.$refs["tableGive"].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit("ALERT", {
          title: "LBLGUIDE", // 안내
          message: "MSGNOITEMS", // 선택된 항목이 없습니다.
          type: "warning", // success / info / warning / error
        });
      } else {
        let items = this.$_.filter(this.$refs["tableGive"].selected, {equipmentRentNumber: ''} || {equipmentRentNumber: null})
        if (items.length === 0) {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '선택한 장비를 대여완료 하시겠습니까?',
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$_.forEach(this.param.giveEquipment.closedSpaceEquipmentReturnItemModels, _item => {
              if (_item.rentPeriod && _item.rentPeriod.length > 0) {
                _item.rentStartDate = _item.rentPeriod[0]
                _item.rentEndDate = _item.rentPeriod[1]
              }
            })
            this.$http.url = this.rentUrl;
            this.$http.type = 'PUT';
            this.$http.param = selectData
            this.$http.request(() => {
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.$emit('emitStep', {
                name: 'getDetail',
                param: { 
                  heaClosedSpaceEquipmentGiveId: this.param.heaClosedSpaceEquipmentGiveId
                },
              })
              // 대여중(1개라도 대여중이면 전체 대여중으로 변경)
                // 전체 대여중으로 업데이트 API호출
                this.$http.url = this.rentStatusUrl;
                this.$http.type = 'PUT';
                this.$http.param = this.param.giveEquipment
                this.$http.request(() => {
                  window.getApp.$emit('APP_REQUEST_SUCCESS');
                })
              this.$refs['tableGive'].$refs['compo-table'].clearSelection();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
        } else {
          window.getApp.$emit('ALERT', {
            title: '안내',
            message: '대여수량을 확인해주세요!',
            // TODO : 필요시 추가하세요.
            type: 'warning', // success / info / warning / error
        });
        }
      }
    },
    returnEquip() {
      let selectData = this.$refs["tableGive"].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit("ALERT", {
          title: "LBLGUIDE", // 안내
          message: "MSGNOITEMS", // 선택된 항목이 없습니다.
          type: "warning", // success / info / warning / error
        });
      } else {
        if (this.$comm.validTable(this.gridGive.columns, this.$refs["tableGive"].selected)) {
          let items = this.$_.filter(this.$refs["tableGive"].selected, {rentStatusCd: 'HCSEGC0002'})
          if (items.length > 0) {
            window.getApp.$emit('CONFIRM', {
              title: '확인',
              message: '선택한 장비 중 신청완료인 건이 존재합니다. 반납 하시겠습니까?',
              // TODO : 필요시 추가하세요.
              type: 'info', // success / info / warning / error
              // 확인 callback 함수
              confirmCallback: () => {
                this.$http.url = this.returnUrl;
                this.$http.type = 'PUT';
                this.$http.param = {
                  heaClosedSpaceEquipmentGiveId: this.param.giveEquipment.heaClosedSpaceEquipmentGiveId, 
                  closedSpaceEquipmentReturnItemModels: selectData,
                  chgUserId: this.$store.getters.user.userId
                }
                this.$http.request(() => {
                  window.getApp.$emit('APP_REQUEST_SUCCESS');
                  this.$emit('emitStep', {
                    name: 'getDetail',
                  })
                  // 반납완료(모든 반납이 끝나면 반납완료로 변경) -> service에서 처리하는 것으로 변경
                  this.$refs['tableGive'].$refs['compo-table'].clearSelection();
                },);
              },
              // 취소 callback 함수
              cancelCallback: () => {
              },
            });
          } else {
            window.getApp.$emit('CONFIRM', {
              title: '확인',
              message: '선택한 장비를 반납 하시겠습니까?',
              // TODO : 필요시 추가하세요.
              type: 'info', // success / info / warning / error
              // 확인 callback 함수
              confirmCallback: () => {
                this.$http.url = this.returnUrl;
                this.$http.type = 'PUT';
                this.$http.param = {
                  heaClosedSpaceEquipmentGiveId: this.param.giveEquipment.heaClosedSpaceEquipmentGiveId, 
                  closedSpaceEquipmentReturnItemModels: selectData,
                  chgUserId: this.$store.getters.user.userId
                }
                this.$http.request(() => {
                  window.getApp.$emit('APP_REQUEST_SUCCESS');
                  this.$emit('emitStep', {
                    name: 'getDetail',
                  })
                  // 반납완료(모든 반납이 끝나면 반납완료로 변경) -> service에서 처리하는 것으로 변경
                  this.$refs['tableGive'].$refs['compo-table'].clearSelection();
                },);
              },
              // 취소 callback 함수
              cancelCallback: () => {
              },
            });
          }
        }
      }
    },
    removeGive() {
      let selectData = this.$refs["tableGive"].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit("ALERT", {
          title: "LBLGUIDE", // 안내
          message: "MSGNOITEMS", // 선택된 항목이 없습니다.
          type: "warning", // success / info / warning / error
        });
      } else {
        this.$http.url = this.deleteItemUrl;
        this.$http.type = 'DELETE';
        this.$http.param = {
          data: Object.values(selectData),
        };
        this.$http.request(() => {
          window.getApp.$emit('APP_REQUEST_SUCCESS');
          
          this.$_.forEach(selectData, item => {
            this.param.giveEquipment.closedSpaceEquipmentReturnItemModels = this.$_.reject(this.param.giveEquipment.closedSpaceEquipmentReturnItemModels, item);
          })
          this.$refs['tableGive'].$refs['compo-table'].clearSelection();
        },);
      }
    },
    addRequestEquips() {
      this.popupOptions.title = '대여장비 신청 상세'; // 대여장비
      this.popupOptions.param = {
        type: 'multiple',
        heaClosedSpaceEquipmentGiveId: this.param.giveEquipment.heaClosedSpaceEquipmentGiveId,
      };
      this.popupOptions.target = () => import(`${'./closedSpaceEquipApplyPop.vue'}`);
      this.popupOptions.width = '60%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeApplyEquipPopup;
    },
    closeApplyEquipPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(this.param.giveEquipment.closedSpaceEquipmentReturnItemModels, { heaClosedSpaceEquipmentId: item.heaClosedSpaceEquipmentId }) === -1) {
            this.param.giveEquipment.closedSpaceEquipmentReturnItemModels.push({
              heaClosedSpaceEquipmentGiveId: this.param.heaClosedSpaceEquipmentGiveId,
              heaClosedSpaceEquipmentReturnItemId: uid(),
              heaClosedSpaceEquipmentId: item.heaClosedSpaceEquipmentId,  
              equipmentName: item.equipmentName,
              equipmentNumber: item.equipmentNumber,  
              equipmentTypeName: item.equipmentTypeName,
              rentStatusCd: item.rentStatusCd,
              rentStatusName: item.rentStatusName,
              rentStartDate: item.rentStartDate,
              rentEndDate: item.rentEndDate,
              rentPeriod: [item.rentStartDate, item.rentEndDate],
              returnDate: '',
              remark: item.remark,
              regUserId: this.$store.getters.user.userId, 
              editFlag: 'C',
            })
          }
        })
      }
    },
    addEquips() {
      this.popupOptions.title = '장비목록 팝업';
      this.popupOptions.param = {
        type: 'multiple'
      };
      this.popupOptions.target = () => import(`${'@/pages/hea/closedSpaceEquipStockPop.vue'}`);
      this.popupOptions.width = '50%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeEquipPopup;
    },
    closeEquipPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        if (!this.param.giveEquipment.closedSpaceEquipmentReturnItemModels) {
          this.param.giveEquipment.closedSpaceEquipmentReturnItemModels = [];
        }
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(this.param.giveEquipment.closedSpaceEquipmentReturnItemModels, { heaClosedSpaceEquipmentId: item.heaClosedSpaceEquipmentId }) === -1) {
            this.param.giveEquipment.closedSpaceEquipmentReturnItemModels.push({
              heaClosedSpaceEquipmentGiveId: this.param.heaClosedSpaceEquipmentGiveId,
              heaClosedSpaceEquipmentReturnItemId: uid(),
              heaClosedSpaceEquipmentId: item.heaClosedSpaceEquipmentId,  
              equipmentName: item.equipmentName,
              equipmentNumber: null,  
              equipmentTypeName: item.equipmentTypeName,
              rentStatusCd: 'HCSEGC0002',
              rentStatusName: '',
              rentStartDate: '',
              rentEndDate: '',
              rentPeriod: [item.rentStartDate, item.rentEndDate],
              regUserId: this.$store.getters.user.userId, 
              returnDate: '',
              editFlag: 'C',
            })
          }
        })
      }
    },
  },
};
</script>